import React from "react";
import MuiWrapper from "../../src/MuiWrapper";

class Layout extends React.Component {
  render() {
    // TODO: remove MUI, use styled-icon
    return (
      <MuiWrapper>
        <div>{this.props.children}</div>
      </MuiWrapper>
    );
  }
}

export default Layout;
