import React from "react";
import styled from "styled-components";
import { dateParser } from "../../../src/Utils/DateUtil";

const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;

  & .row {
    display: flex;
    align-items: stretch;
    min-height: 30px;
    margin-bottom: 14px;

    .cell {
      flex: 0;
      flex-shrink: 0;
      flex-basis: 100px;
      padding: 5px 10px;
      background-color: white;
      text-align: center;
    }

    .cell.header {
      font-weight: bold;
      color: #a4a8b1;
      padding: 20px 5px;
      text-align: center;
      border-top: 1px solid #e4e4e4;
      border-bottom: 1px solid #ccc;
      background-color: transparent;
    }

    .cell:first-child {
      cursor: pointer;
      color: blue;
      text-decoration: underline;
    }

    .cell.header:first-child {
      color: #a4a8b1;
      text-decoration: none;
    }

    .cell:first-child:hover {
      font-weight: bold;
    }

    .cell:last-child {
      flex-grow: 1;
    }
  }
`;

const Cell = styled.div`
  ${props => props.css || ""}
`;

class Table extends React.Component {
  render() {
    let { model, instances, onCellClick, customizeCells = {} } = this.props;
    return (
      <Wrapper>
        <TableHeader model={model} />

        {instances.map((instance, idx) => (
          <TableRow
            key={idx}
            instance={instance}
            model={model}
            onCellClick={onCellClick}
            customizeCells={customizeCells}
          />
        ))}
      </Wrapper>
    );
  }
}

class TableRow extends React.Component {
  render() {
    let { model, instance, onCellClick, customizeCells } = this.props;
    return (
      <div className="row">
        {model.fields.map((field, idx) => {
          let extraProps = {};

          if (idx === 0) {
            extraProps.onClick = () => onCellClick({ instance });
          }

          if (
            field.name.indexOf("customize-") === 0 &&
            customizeCells[field.name]
          ) {
            try {
              return (
                <Cell
                  className="cell"
                  key={idx}
                  style={field.style || {}}
                  {...extraProps}
                >
                  {customizeCells[field.name]["renderCustomizeCell"](instance)}
                </Cell>
              );
            } catch (err) {
              throw new Error(
                `format mismatch, ex: customizeCells: { 'customize-1': { renderCustomizeCell: () => {} } }`
              );
            }
          }

          return (
            <Cell
              className="cell"
              key={idx}
              style={field.style || {}}
              {...extraProps}
            >
              {(() => {
                if (field.type === "datetime") {
                  return dateParser(instance[field.name]);
                }

                try {
                  return field.extra.displayMap[`${instance[field.name]}`];
                } catch (err) {
                  return `${instance[field.name]}`;
                }
              })()}
            </Cell>
          );
        })}
      </div>
    );
  }
}

class TableHeader extends React.Component {
  render() {
    let { model } = this.props;
    return (
      <div className="row">
        {model.fields.map((field, idx) => (
          <Cell className="cell header" key={idx} style={field.style || {}}>
            {field.label || field.name}
          </Cell>
        ))}
      </div>
    );
  }
}

export default Table;
