import React from "react";
import styled from "styled-components";
import * as Icon from "../Icon";
import AppConfig from "../../Config";

const globalThemeColor = AppConfig.global.themeColor;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;

  & > .admin-rwd-toggle {
    display: none;

    @media screen and (max-width: 600px) {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      right: 20px;
      bottom: 20px;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      cursor: pointer;
      background-color: ${props => props.themeColor || globalThemeColor};
    }
  }

  & > .admin-sidebar {
    @media screen and (max-width: 600px) {
      position: fixed;
      height: 100%;
      left: ${props => (props.rwdSidebarOpen ? "0px" : "-200px")};
      transition: left 200ms;
    }

    width: 200px;
    flex-shrink: 0;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
    background-color: white;
    border-left: 10px solid ${props => props.themeColor || globalThemeColor};
    z-index: 1;

    .site-title {
      padding: 10px;
      font-weight: bold;
      color: ${props => props.themeColor || globalThemeColor};
    }

    .entry {
      padding: 15px 18px;
      display: flex;
      cursor: pointer;
    }

    .entry .label {
      flex: 1;
    }

    .entry.selected {
      color: white;
      background-color: ${props => props.themeColor || globalThemeColor};
    }

    .entry.level2 {
      padding-left: 25px;
    }

    .entry:hover {
      color: white;
      background-color: ${props => props.themeColor || globalThemeColor};
      cursor: pointer;
    }
  }

  & > .admin-tab-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow-y: auto;
    background-color: #f9f9f9;
  }
`;

class CmsDashboard extends React.Component {
  static defaultProps = {
    Header: "Revtel Admin",
    entryFunctionHandler: () => 0
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedEntry: this.props.entries[0],
      rwdSidebarOpen: false
    };
  }

  render() {
    let { entries, themeColor } = this.props;
    let { selectedEntry, rwdSidebarOpen } = this.state;

    return (
      <Wrapper themeColor={themeColor} rwdSidebarOpen={rwdSidebarOpen}>
        <div className="admin-sidebar">
          {this._renderHeader()}

          {entries.map(entry => (
            <CmsEntry
              key={entry.name}
              entry={entry}
              selectedEntry={selectedEntry}
              level={1}
              onSelectEntry={this._onSelectEntry}
            />
          ))}
        </div>

        <div className="admin-tab-content">{this._renderContent()}</div>

        <div className="admin-rwd-toggle" onClick={this._toggleRwdSidebar}>
          <Icon.Apps size={24} color="white" />
        </div>
      </Wrapper>
    );
  }

  _renderHeader = () => {
    let { Header } = this.props;
    let { selectedEntry } = this.state;

    if (typeof Header === "string") {
      return <div className="site-title">Revtel Admin</div>;
    } else if (typeof Header === "function") {
      // Component
      return <Header selectedEntry={selectedEntry} />;
    } else if (typeof Header === "object") {
      // Element
      return Header;
    }
  };

  _renderContent = () => {
    let { selectedEntry } = this.state;
    try {
      return this.props.children({
        cms: {
          entry: selectedEntry
        }
      });
    } catch (ex) {
      return <div>{selectedEntry.name}</div>;
    }
  };

  _onSelectEntry = entry => {
    let { entryFunctionHandler } = this.props;
    if (entry.type === "function") {
      entryFunctionHandler(entry);
    } else {
      this.setState({ selectedEntry: entry, rwdSidebarOpen: false });
    }
  };

  _toggleRwdSidebar = () => {
    let { rwdSidebarOpen } = this.state;
    this.setState({ rwdSidebarOpen: !rwdSidebarOpen });
  };
}

class CmsEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false
    };
  }

  render() {
    let { entry, level, selectedEntry, onSelectEntry } = this.props;
    let { expand } = this.state;
    let isSelected = selectedEntry.name === entry.name;

    return (
      <>
        <div
          className={
            "entry " + `level${level} ` + (isSelected ? "selected" : "")
          }
          onClick={this._onEntryClick}
        >
          <div className="label">{entry.label}</div>

          {entry.entries && <div className="expand">{expand ? "-" : "+"}</div>}
        </div>

        {expand &&
          entry.entries.map(e => (
            <CmsEntry
              level={level + 1}
              entry={e}
              selectedEntry={selectedEntry}
              onSelectEntry={onSelectEntry}
              key={e.name}
            />
          ))}
      </>
    );
  }

  _onEntryClick = () => {
    let { entry, onSelectEntry } = this.props;
    let { expand } = this.state;

    if (entry.entries) {
      this.setState({ expand: !expand });
    } else {
      onSelectEntry(entry);
    }
  };
}

export default CmsDashboard;
