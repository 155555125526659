import { navigate } from "gatsby";
import Actions from "../Reducers/Actions";
import ApiProxy from "../../src/ApiProxy";
import Catchify from "../../src/Utils/Catchify";
import API from "../../src/Api";
import srcActionCreator from "../../src/ActionCreator";

export default dispatch => {
  const srcActions = srcActionCreator(dispatch);

  return {
    navActions: {
      ...srcActions.navActions
    },

    appActions: {
      ...srcActions.appActions,

      getMyStore: async () => {
        try {
          let results = await API.getMyStore();
          dispatch({ type: Actions.STORE, payload: results });
        } catch (err) {
          console.warn(err);
        }
      },

      editStore: async (id, data) => {
        let result = await API.editStore(id, data);
        return result;
      },

      deleteStoreImagesById: async id => {
        try {
          let results = API.deleteStoreImagesById(id);
          return results;
        } catch (err) {
          console.warn(err);
          throw err;
        }
      },

      createStoreImages: async (id, data) => {
        try {
          let results = API.createStoreImages(id, data);
          return results;
        } catch (err) {
          console.warn(err);
          throw err;
        }
      },

      deleteProductImagesById: async id => {
        try {
          let results = API.deleteProductImagesById(id);
          return results;
        } catch (err) {
          console.warn(err);
          throw err;
        }
      },

      createProductImages: async (id, data) => {
        try {
          let results = API.createProductImages(id, data);
          return results;
        } catch (err) {
          console.warn(err);
          throw err;
        }
      },

      listStoreOrder: async ({ store, tokenType, from, to }) => {
        try {
          return API.listStoreOrder({ store, tokenType, from, to });
        } catch (err) {
          return Promise.reject(err);
        }
      },

      updateStoreOrder: async (id, data) => {
        try {
          return API.updateStoreOrder(id, data);
        } catch (err) {
          return Promise.reject(err);
        }
      }
    }
  };
};
