import React from "react";
import { connect } from "react-redux";
import { withPage } from "../../Page";
import styled from "styled-components";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import * as Icon from "../../Components/Icon";
import LoginForm from "../../Components/LoginForm";
import ProfileForm from "../../Components/ProfileForm";
import StoreForm from "../../Components/StoreForm";
import HomeTable from "../../Components/HomeTable";
import ProductTable from "../../Components/ProductTable";
import StoreOrderTable from "../../Components/StoreOrderTable";
import CmsDashboard from "../../../src/Components/CmsDashboard";
import CmsTableFactory from "../../../src/Components/CmsTableFactory";
import AppConfig from "../../Config";

class AdminPage extends React.Component {
  render() {
    let { profile } = this.props;

    if (!profile) {
      return <LoginForm />;
    } else {
      return (
        <CmsDashboard
          themeColor={AppConfig.global.themeColor}
          Header={
            <CustomHeader>
              <h3>Custom Admin</h3>
            </CustomHeader>
          }
          entries={[
            {
              name: "home",
              label: "首頁"
            },
            {
              name: "product",
              label: "Product"
            },
            {
              name: "biz",
              label: "Business",
              entries: [
                // { name: "store", label: "Store" },
                { name: "order", label: "Orders" }
              ]
            },
            { name: "store", label: "Store" },
            { name: "store-order", label: "Store Order" },
            {
              name: "settings",
              label: "Settings"
            },
            {
              name: "profile",
              label: "Profile"
            },
            {
              name: "logout",
              label: "Logout",
              type: "function"
            }
          ]}
          entryFunctionHandler={this._onEntryFunction}
        >
          {({ cms }) => {
            let { entry } = cms;
            if (entry.name === "home") {
              return <HomeTable />;
            } else if (entry.name === "product") {
              return <ProductTable />;
            } else if (entry.name === "store-order") {
              return <StoreOrderTable />;
            } else if (entry.name === "store") {
              return <StoreForm css={"padding: 10px 30px;"} />;
            } else if (entry.name === "profile") {
              return (
                <div style={{ padding: "10px 30px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 20
                    }}
                  >
                    <Icon.AccountCircle size={36} />
                    <h2>Hello, {profile.user.username}</h2>
                  </div>

                  <ProfileForm profile={profile} />
                </div>
              );
            }

            throw "default";
          }}
        </CmsDashboard>
      );
    }
  }

  _onEntryFunction = entry => {
    let { appActions } = this.props;
    if (entry.name === "logout") {
      appActions.logout().catch(console.warn);
    }
  };
}

const CustomHeader = styled.div`
  background-color: white;
  color: ${AppConfig.global.themeColor};
  padding: 12px 8px;
  min-height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .h2 {
    margin: 0px;
    padding: 0px;
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state)
    }),
    ActionCreator
  )(AdminPage)
);
